import { i18n } from '@/i18n';
import { SettingsStorage } from '@/modules/settings/settings-storage';
import IdField from '@/shared/fields/id-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import { WorkPackageField } from '@/modules/work-package/work-package-field';
import FilesField from '@/shared/fields/files-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';

function label(name) {
  return i18n(`entities.projectGantt.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  workPackage: WorkPackageField.relationToMany(
    'workPackage',
    label('workPackage'),
    {},
  ),
  ganttChart: new FilesField(
    'ganttChart',
    label('ganttChart'),
    Storage.values.projectGanttGanttChart,
    Permissions.getFilePermissions(
      'projectGanttFileFields',
      'ganttChart',
    ),
    {
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx', 'xlsx', 'xls'],
    },
  ),
  status: new EnumeratorField(
    'status',
    label('status'),
    [
      { id: 'inProgress', label: label('status', 'inProgress') },
      { id: 'completed', label: label('status', 'completed') },
    ],
    {},
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class ProjectGanttModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
