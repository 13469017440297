import { WorkPackageService } from '@/modules/work-package/work-package-service';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import RelationToManyField from '@/shared/fields/relation-to-many-field';
import Permissions from '@/security/permissions';
import moment from 'moment';

export class WorkPackageField {
  static relationToOne(name, label, options) {
    return new RelationToOneField(
      name,
      label,
      '',
      Permissions.values.workPackageRead,
      Permissions.values.workPackageNavigate,
      WorkPackageService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        return {
          id: record.id,
          label:`${record.name} - ${record.milestone.map(f => moment(f).format('DD.MM.YYYY')).join(' - ')}`,
        };
      },
      options,
    );
  }

  static relationToMany(name, label, options) {
    return new RelationToManyField(
      name,
      label,
      '',
      Permissions.values.workPackageRead,
      Permissions.values.workPackageNavigate,
      WorkPackageService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        return {
          id: record.id,
          label:`${record.name} - ${record.milestone.map(f => moment(f).format('DD.MM.YYYY')).join(' - ')}`,
        };
      },
      options,
    );
  }
}
