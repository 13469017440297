<template>
  <div>
    <br />
    <div
      class="app-page-spinner"
      v-if="loading"
      v-loading="loading"
    ></div>

    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      @submit.prevent.native
      class="form"
      v-if="record"
    >
      <app-view-item-relation-to-many
        :label="fields.workPackage.label"
        :readPermission="fields.workPackage.readPermission"
        :url="fields.workPackage.viewUrl"
        :value="presenter(record, 'workPackage')"
      ></app-view-item-relation-to-many>

      <app-view-item-file
        :label="fields.ganttChart.label"
        :value="presenter(record, 'ganttChart')"
        :permissions="fields.ganttChart.permissions"
      ></app-view-item-file>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ProjectGanttModel } from '@/modules/project-gantt/project-gantt-model';

const { fields } = ProjectGanttModel;

export default {
  name: 'app-project-gantt-view-page',

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'projectGantt/view/record',
      loading: 'projectGantt/view/loading',
      projectGanttId: 'project/form/projectGanttId',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.projectGanttId);
  },

  methods: {
    ...mapActions({
      doFind: 'projectGantt/view/doFind',
    }),

    presenter(record, fieldName) {
      return ProjectGanttModel.presenter(record, fieldName);
    },
  },
};
</script>
